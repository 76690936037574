import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SessionProvider } from 'src/context/SessionContext';
import { configVars } from 'src/helpers';
import { DUPPageProvider } from 'src/pages/DUPPage/DUPPageProvider';
import { PartnerTermsPage } from 'src/pages/PartnerTermsPage';
import { PrivacyPolicyPage } from 'src/pages/PrivacyPolicyPage';
import { TermsPage } from 'src/pages/TermsPage';
import { WelcomePage } from 'src/pages/WelcomePage';
import { DupApplicationType } from 'src/types/api';

export const App: React.FC = () => {
  useEffect(() => {
    const favicon = document.getElementById('favicon') as HTMLAnchorElement;
    favicon.href = '/assets/images/DocumentIcon.svg';
    document.title = 'Document Portal';
  });

  return (
    <>
      {configVars.heap.app_id && (
        <HelmetProvider>
          <Helmet>
            <script type="text/javascript">
              {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};   
heap.load("${configVars.heap.app_id}");`}
            </script>
          </Helmet>
        </HelmetProvider>
      )}
      <BrowserRouter>
        <Routes>
          <Route
            path="/application/applyonbehalf/:companyId/:propertyId"
            element={
              <SessionProvider applicationType={DupApplicationType.LEASING_TEAM}>
                <DUPPageProvider />{' '}
              </SessionProvider>
            }
          />
          <Route
            path="/application/apply/:companyId/:propertyId"
            element={
              <SessionProvider applicationType={DupApplicationType.UNAUTHENTICATED_USER}>
                <DUPPageProvider />{' '}
              </SessionProvider>
            }
          />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/partner-terms" element={<PartnerTermsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route path="/" element={<WelcomePage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
