import { useCallback, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { ProofForBox } from 'src/components/DUP/molecules/BoxProof/BoxProof';
import { WizardProofOfIncomeProps } from 'src/components/DUP/molecules/WizardProofOfIncome/WizardProofOfIncome';
import { DocumentRow } from 'src/components/DUP/molecules/WizardUpload/DocumentRow';
import { useWizardUploadInstructions } from 'src/components/DUP/molecules/WizardUpload/useWizardUploadInstructions';
import { FILES_TO_UPLOAD } from 'src/features/DUP/proofs/constants';
import { useRequest } from 'src/hooks/useResource';
import { CreateProofRequest, JobErrorType, ProofResponse } from 'src/types/api';
import { v4 as uuidv4 } from 'uuid';
import { CenteredColumn, StyledIcon, StyledText, SubText, UploadsContainer } from './styles';

export type WizardUploadProps = Pick<
  WizardProofOfIncomeProps,
  'proofType' | 'property' | 'proofs' | 'refresh'
>;

/**
 * This hook handles file uploads in the wizard
 *
 * It returns the following:
 * - UploadedDocumentsSection: The list of uploaded documents
 * - WizardUploadEl: The upload section, uploaded docs + instructions
 * - proofIsProcessing: A boolean indicating if a proof is being processed
 * - instructions: The upload instructions
 */
export const useWizardUpload = ({ proofType, property, proofs, refresh }: WizardUploadProps) => {
  const { t } = useTranslation();

  const [uploadingFiles, setUploadingFiles] = useState<ProofForBox[]>([]);
  const [sectionFiles, setSectionFiles] = useState<ProofForBox[]>(
    proofs.filter((p) => p.type === proofType)
  );

  const { makeRequest } = useRequest();

  const [proofIsProcessing, setProofIsProcessing] = useState(false);

  const { instructions, InstructionsEl } = useWizardUploadInstructions({ property, proofType });

  const handleUploadFiles = useCallback(
    async (proofsToUpload: CreateProofRequest[]) => {
      if (proofsToUpload.length > FILES_TO_UPLOAD.MAX_FILES) {
        alert(t('dup.proof.maxFiles', { count: FILES_TO_UPLOAD.MAX_FILES }));
        return [];
      }

      if (proofsToUpload.length + proofs.length > FILES_TO_UPLOAD.MAX_SUBMISSION_FILES) {
        alert(t('dup.proof.maxFiles', { count: FILES_TO_UPLOAD.MAX_SUBMISSION_FILES }));
        return [];
      }

      setProofIsProcessing(true);

      const failedDocs: ProofForBox[] = [];
      for (const { upload, type } of proofsToUpload) {
        const formData = new FormData();
        formData.append('upload', upload);
        formData.append('type', type);

        const response = await makeRequest<
          ProofResponse | { error: string; failedChecks: JobErrorType[] }
        >({
          path: `/session/documents?checks=true`,
          method: 'POST',
          payload: formData,
          isFormData: true
        });

        if ('error' in response) {
          const jobs_error = getJobsError(response);

          failedDocs.push({
            id: uuidv4(),
            fileName: upload.name,
            type,
            jobs_error,
            thumb: ''
          });
        }
      }

      await refresh?.();
      setProofIsProcessing(false);

      return failedDocs;
    },
    [proofs.length, refresh, t, makeRequest]
  );

  const onFilesSelect = useCallback(
    async (chosenFiles: File[], fileRejections: FileRejection[]) => {
      const placeholderFiles = chosenFiles.map(
        (upload: File): ProofForBox => ({
          id: upload.name,
          fileName: upload.name,
          type: proofType,
          thumb: '',
          jobs_error: [],
          isReplacing: false,
          isLoading: true
        })
      );

      const nonPDFDraggedFiles = fileRejections.filter((rejection) =>
        rejection.errors?.find((error) => error.code === 'file-invalid-type')
      );
      const errorFiles = nonPDFDraggedFiles.map(
        ({ file }): ProofForBox => ({
          id: uuidv4(),
          fileName: file.name,
          type: proofType,
          jobs_error: ['UnsupportedFileTypeError'] as JobErrorType[]
        })
      );

      setUploadingFiles((prevState) => [...prevState, ...placeholderFiles, ...errorFiles]);

      const failedFiles = await handleUploadFiles(
        chosenFiles.map((upload) => ({
          upload,
          type: proofType
        })) satisfies CreateProofRequest[]
      );

      setUploadingFiles((prevState) => [
        ...prevState.filter((p) => !placeholderFiles.includes(p)),
        ...failedFiles
      ]);
    },
    [handleUploadFiles, proofType]
  );

  const onDeleteProof = async (proofId: string) => {
    if (!proofs.find((proof) => proof.id === proofId)) {
      // If the proof is an error placeholder not uploaded to the session, just remove the UI piece
      // Removing from uploadingProofs since list contains error files
      const uploadingProofsAdjustedArray = uploadingFiles.filter(
        (uploadingFile) => uploadingFile.id !== proofId
      );

      setUploadingFiles(uploadingProofsAdjustedArray);
    } else {
      // Actual proof uploaded to the session removal
      if (!proofIsProcessing) {
        setProofIsProcessing(true);
        await makeRequest({
          path: `/session/documents/${proofId}`,
          method: 'DELETE'
        });
        await refresh?.();
        setUploadingFiles((prevState) => prevState.filter((f) => f.id !== proofId));
        setProofIsProcessing(false);
      }
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      onFilesSelect(acceptedFiles, fileRejections);
    },
    [onFilesSelect]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'application/pdf': ['.pdf'] }
  });

  useEffect(() => {
    const newProofs = proofs.filter((p) => {
      if (p.type !== proofType) {
        return false;
      }
      const match = sectionFiles.find((proof) => proof.id === p.id);
      return !match;
    });

    // Iterating over sectionFiles to maintain order of proofs
    const adjustedProofs = sectionFiles.reduce((acc: ProofForBox[], proof) => {
      if (!proofs.find((p) => proof.id === p.id)) {
        if (proof?.jobs_error?.[0] === 'UnsupportedFileTypeError') {
          // If the proof is not found on the session, but it's an error placeholder, keep it
          return [...acc, proof];
        } else {
          // If the proof is not found on the session, and it's not an error placeholder, remove it
          return acc;
        }
      }

      return [...acc, proof];
    }, []);

    setSectionFiles([...adjustedProofs, ...newProofs]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proofs, proofType]);

  const DocumentUploadEl = (
    <UploadsContainer {...getRootProps()}>
      <input {...getInputProps()} />

      <StyledIcon icon="upload_cloud" />
      <StyledText>
        <span>{t('dup.wizard.instructions.clickToUpload')} </span>
        {t('dup.wizard.instructions.dragAndDrop')}
      </StyledText>
      <SubText>{t('dup.wizard.instructions.pdfOnly')}</SubText>
    </UploadsContainer>
  );

  // The proofs array are the fully uploaded documents. Mix in documents we're
  // currently uploading to create the displayed set.
  const sectionProofs: ProofForBox[] = [...sectionFiles, ...uploadingFiles];

  const UploadedDocumentsSection = (
    <CenteredColumn gap={0.75}>
      {sectionProofs.map((proof, index) => (
        <DocumentRow
          key={`${proof.id}-${index}`}
          proof={proof}
          type={proofType}
          rowNumber={index + 1}
          onDeleteProof={onDeleteProof}
        />
      ))}
    </CenteredColumn>
  );

  // complete element with upload section, uploaded docs + instructions
  const WizardUploadEl = (
    <CenteredColumn gap={1.5}>
      {DocumentUploadEl}
      {UploadedDocumentsSection}
      {InstructionsEl}
    </CenteredColumn>
  );

  return { UploadedDocumentsSection, WizardUploadEl, proofIsProcessing, instructions };
};

const getJobsError = (response: {
  error: string;
  failedChecks?: JobErrorType[];
}): JobErrorType[] => {
  const overSizeError = response.error.includes('File too large');

  if (overSizeError) {
    return ['PDFOver25MBError'];
  }
  if (response.failedChecks?.length) {
    // Account for the possibility of receiving an empty array of failed checks
    return response.failedChecks;
  }

  return ['GenericError'];
};
