import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loader } from 'src/components/atoms/Loader';
import Section from 'src/components/atoms/Section/Section';
import { WizardBody } from 'src/components/DUP/atoms/WizardBody';
import { Success } from 'src/components/DUP/molecules/Success';
import { WizardDocumentUploadSection } from 'src/components/DUP/molecules/WizardDocumentUploadSection/WizardDocumentUploadSection';
import WizardFooter from 'src/components/DUP/molecules/WizardFooter';
import WizardFormApplicant from 'src/components/DUP/molecules/WizardFormApplicant';
import { WizardHeader } from 'src/components/DUP/molecules/WizardHeader';
import WizardProofOfIncome from 'src/components/DUP/molecules/WizardProofOfIncome/WizardProofOfIncome';
import { WizardSubmit } from 'src/components/DUP/molecules/WizardSubmit';
import { ResultMessage } from 'src/components/molecules/ResultMessage';
import { ResultMessageType } from 'src/components/molecules/ResultMessage/ResultMessage';
import useInformation, { Information } from 'src/hooks/useInformation';
import { useResource } from 'src/hooks/useResource';
import { WizardContainer } from 'src/pages/DUPPage/styles';
import {
  DupApplicationType,
  Proof,
  SessionApplication,
  SessionProperty,
  UnauthenticateSessionProofType
} from 'src/types/api';

// Use this type to define the props for each of the sub-components in the DUP wizard
export interface DupScreenStepProps {
  name: string;
  onFinished: () => void;
  information: Information;
  property: SessionProperty;
  application: SessionApplication;
  setActiveStep: (step: number) => void;
  onUpdateApplication: (
    updates: Partial<SessionApplication>
  ) => Promise<SessionApplication | { error: string }>;
}

/**
 * This component is the main entry point for the DUP wizard.
 * It will display the header, footer, and the various steps in the wizard.
 */
export const DUPWizard: React.FC<{
  type: DupApplicationType;
  application: SessionApplication;
  onUpdateApplication: (
    updates: Partial<SessionApplication>
  ) => Promise<SessionApplication | { error: string }>;
}> = ({ type, application, onUpdateApplication }) => {
  useEffect(() => {
    document.title = 'Snappt AX';
    if (window.heap) {
      window.heap.clearEventProperties();
      window.heap.addEventProperties({ isSnAX: 'true' });
    }
  });

  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);

  const [property] = useResource<SessionProperty | { error: string }>(`/session/property`);
  const information = useInformation(type, property && 'name' in property ? property.name : '');
  const [proofs = [], { refresh }] = useResource<Proof[]>(`/session/documents`);

  if ('error' in application || (!!property && 'error' in property) || 'error' in proofs) {
    return (
      <Section align="center">
        <ResultMessage
          type={ResultMessageType.error}
          title={t('dup.submission.error.title')}
          message={t('dup.submission.error.message')}
        />
      </Section>
    );
  }

  if (!property) {
    return <Loader isFixed />;
  }

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const stepComponentProps = {
    property,
    information,
    application,
    onUpdateApplication,
    onFinished: handleNext,
    setActiveStep
  };

  const wizardComponents = [
    <WizardFormApplicant name="step1" key={0} {...stepComponentProps} />,
    <WizardProofOfIncome
      name="step2"
      key={1}
      proofType={UnauthenticateSessionProofType.Paystub}
      proofs={proofs}
      refresh={() => refresh()}
      {...stepComponentProps}
    />,
    <WizardDocumentUploadSection
      name="step3"
      key={2}
      proofType={UnauthenticateSessionProofType.BankStatement}
      proofs={proofs}
      refresh={() => refresh()}
      {...stepComponentProps}
    />,
    <WizardSubmit name="reviewAndSubmit" key={3} proofs={proofs} {...stepComponentProps} />,
    <Success name="success" key={4} {...stepComponentProps} />
  ];

  return (
    <WizardContainer>
      <WizardHeader
        property={property}
        activeStep={activeStep + 1}
        goToStep={(step: number) => {
          setActiveStep(step - 1);
        }}
      />
      <WizardBody activeStep={activeStep}>
        {wizardComponents[activeStep]}
        {activeStep !== 4 && <WizardFooter hideOn="desktop" />}
      </WizardBody>
      {activeStep !== 4 && <WizardFooter hideOn="mobile" />}
    </WizardContainer>
  );
};
