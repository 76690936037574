// src/contexts/SessionContext.tsx
import { datadogLogs } from '@datadog/browser-logs';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Loader } from 'src/components/atoms/Loader';
import { UNAUTHENTICATED_SESSION_TOKEN_KEY } from 'src/features/DUP';
import { configVars } from 'src/helpers';
import { useSessionStorage } from 'src/hooks/useSessionStorage';
import { DupApplicationType } from 'src/types/api';

export interface SessionContextProps {
  sessionToken: string | undefined;
  setSessionToken: (token: string) => void;
  applicantIdentifier?: string;
  resetSession: () => void;
  applicationType: DupApplicationType;
}

const SessionContext = createContext<SessionContextProps | undefined>(undefined);

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within a SessionProvider');
  }
  return context;
};

interface SessionProviderProps {
  children: React.ReactNode;
  applicationType: DupApplicationType;
}

export const SessionProvider = ({ children, applicationType }: SessionProviderProps) => {
  const { companyId, propertyId } = useParams();
  const { search } = useLocation();
  const currentApplicantIdentifier = new URLSearchParams(search).get('applicantIdentifier');
  const [applicantIdentifier, setApplicantIdentifier] = useState(currentApplicantIdentifier ?? '');
  const [sessionToken, setSessionToken] = useSessionStorage(UNAUTHENTICATED_SESSION_TOKEN_KEY, '');
  const [loadingSession, setLoadingSession] = React.useState<boolean>(!sessionToken);
  const navigate = useNavigate();

  const resetSession = useCallback(() => {
    setLoadingSession(true);
    setSessionToken('');
  }, [setSessionToken, setLoadingSession]);

  useEffect(() => {
    const getSessionToken = async () => {
      setLoadingSession(true);
      const hostPath = configVars.epa_url;
      const options: RequestInit = {
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({
          companyShortId: companyId,
          propertyShortId: propertyId,
          applicantIdentifier: applicantIdentifier ? applicantIdentifier : undefined
        })
      };
      try {
        const resp = await fetch(
          `${hostPath}/session/public?applicationType=${applicationType}`,
          options
        );
        const result = await resp.json();
        if ('token' in result) {
          setSessionToken(result.token);
        } else if ('error' in result) {
          datadogLogs.logger.info('[SessionContext] Error creating application', {
            error: result.error
          });
          navigate(`/?error=${encodeURIComponent(result.error)}`);
        }

        if ('applicantDetailId' in result) {
          setApplicantIdentifier(result.applicantDetailId);
        }
      } catch (err) {
        console.error({ error: `${err}` });
      }
      setLoadingSession(false);
    };
    if (!sessionToken) {
      getSessionToken();
    } else {
      setLoadingSession(false);
    }
  }, [
    sessionToken,
    applicationType,
    setSessionToken,
    companyId,
    propertyId,
    applicantIdentifier,
    loadingSession,
    navigate
  ]);

  return (
    <SessionContext.Provider
      value={{
        sessionToken,
        setSessionToken,
        resetSession,
        applicantIdentifier,
        applicationType
      }}
    >
      {loadingSession ? <Loader isFixed /> : children}
    </SessionContext.Provider>
  );
};
